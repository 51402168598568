import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../Helper/PageLoader";
import { bankAccountSaveStart, bankAccountViewStart } from "../../store/slices/WithdrawSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const BankAccountCreate = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("bankAccount_create");
  const params = useParams();
  const bankAccountSave = useSelector((state) => state.withdraw.bankAccountSave);
  const bankAccountView = useSelector((state) => state.withdraw.bankAccountView);
  const profile = useSelector((state) => state.admin.profile);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const account_options = [
    { value: "current", label: "Current" },
    { value: "savings", label: "Savings" }
  ]

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string()
      .required(t("bank_name.required")),
    account_holder_name: Yup.string().required(t("account_holder_name.required")),
    account_number: Yup.string()
      .required(t("account_number.required")),
    account_number_confirmation: Yup.string()
      .required(t("account_number_confirmation.required"))
      .when("account_number", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("account_number")],
          t("account_number_confirmation.invalid")
        ),
      }),
    ifsc_code: Yup.string()
      .required(t("ifsc_code.required")),
    account_type: Yup.string()
      .required(t("account_type.required"))
  });

  useEffect(() => {
    if (
      !profile.loading &&
      Object.keys(profile.data).length > 0 &&
      params.bankAccountId
    )
      dispatch(bankAccountViewStart({ bank_account_id: params.bankAccountId }));
  }, [params.bankAccountId]);

  const handleSubmit = (values) => {
    dispatch(
      bankAccountSaveStart({
        ...values,
        bank_account_id: params.bankAccountId || "",
      })
    );
  };

  useEffect(() => {
    if (!skipRender && Object.keys(bankAccountSave.data).length > 0) {
      navigate("/bank-accounts")
    }
    setSkipRender(false);
  }, [bankAccountSave])

  return (
    <>
      <div className="bank-account-sec">
        {params.bankAccountId && bankAccountView.loading ? (
          <PageLoader />
        ) : Object.keys(bankAccountView.data).length > 0 ||
          !params.bankAccountId ? (
          <div className="bank-account-box">
            <div className="create-account-to-sec">
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              clipRule="evenodd"
              viewBox="0 0 512 512"
            >
              <path
                fill="#202931"
                d="M256 26.701C129.446 26.701 26.701 129.446 26.701 256S129.446 485.299 256 485.299 485.299 382.554 485.299 256 382.554 26.701 256 26.701zm0 50c98.958 0 179.299 80.341 179.299 179.299S354.958 435.299 256 435.299 76.701 354.958 76.701 256 157.042 76.701 256 76.701zm36.386 229.037L242.647 256l49.739-49.738c9.756-9.757 9.756-25.599 0-35.356-9.757-9.756-25.599-9.756-35.356 0l-67.416 67.416c-9.763 9.763-9.763 25.593 0 35.356l67.416 67.416c9.757 9.756 25.599 9.756 35.356 0 9.756-9.757 9.756-25.599 0-35.356z"
              ></path>
            </svg>
              <h3>{params.bankAccountId ? t("edit") : t("add")} {t("your_bank_account")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="bank-account-form-sec">
                  <Formik
                    initialValues={
                      params.bankAccountId
                        ? {
                          bank_name: bankAccountView.data.bank_account.bank_name,
                          account_holder_name: bankAccountView.data.bank_account.account_holder_name,
                          account_number: bankAccountView.data.bank_account.account_number,
                          account_number_confirmation: bankAccountView.data.bank_account.account_number,
                          ifsc_code: bankAccountView.data.bank_account.ifsc_code,
                          account_type: bankAccountView.data.bank_account.account_type,
                        }
                        : {
                          bank_name: "",
                          account_holder_name: "",
                          account_number: "",
                          account_number_confirmation: "",
                          ifsc_code: "",
                          account_type: "",
                        }
                    }
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue, setFieldTouched, errors }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("bank_name.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("bank_name.placeholder")}
                            name="bank_name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_name"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("account_holder_name.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("account_holder_name.placeholder")}
                            name="account_holder_name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_holder_name"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("account_number.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("account_number.placeholder")}
                            name="account_number"
                            type="text"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("account_number_confirmation.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("account_number_confirmation.placeholder")}
                            name="account_number_confirmation"
                            type="text"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number_confirmation"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("account_type.label")}</Form.Label>
                          <Select
                            options={account_options}
                            styles={customStyles}
                            isSearchable={false}
                            value={params.bankAccountId && {
                              label: values.account_type.charAt(0).toUpperCase() + values.account_type.slice(1),
                              value: values.account_type,
                            }}
                            onChange={(selectedOption) => {
                              setFieldValue("account_type", selectedOption.value);
                            }}
                            placeholder={t("account_type.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_type"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("ifsc_code.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("ifsc_code.placeholder")}
                            name="ifsc_code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="ifsc_code"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button type="button" 
                            className="snapgopay-cancel-btn" 
                            onClick={()=> {
                              setFieldValue("bank_name", params.bankAccountId ? bankAccountView.data.bank_account.bank_name : "")
                              setFieldValue("account_holder_name", params.bankAccountId ? bankAccountView.data.bank_account.account_holder_name : "")
                              setFieldValue("account_number", params.bankAccountId ? bankAccountView.data.bank_account.account_number : "")
                              setFieldValue("account_number_confirmation", params.bankAccountId ? bankAccountView.data.bank_account.account_number : "")
                              setFieldValue("ifsc_code", params.bankAccountId ? bankAccountView.data.bank_account.ifsc_code : "")
                              // setFieldValue("account_type", params.bankAccountId ? bankAccountView.data.bank_account.account_type : "")
                            }}
                          >
                            {t("reset")}
                          </Button>
                          <Button type="submit" className="snapgopay-auth-btn" disabled={bankAccountSave.buttonDisable}>
                            {bankAccountSave.buttonDisable ? <ButtonLoader /> : t("save_btn.text")}
                          </Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
              <Col md={6}>
                <div className="bank-account-img-sec">
                  <Image
                    className="bank-account-img"
                    src={window.location.origin + "/img/bank-account-img.png"}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BankAccountCreate;

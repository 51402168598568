import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import OtpInput from "react-otp-input";
import {
  emailVerifyStart,
  resendOtpStart,
  updateEmailCodeStart,
  updateEmailStart,
} from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { ButtonLoader } from "../Helper/Loader";

const OTPVerificationModal = (props) => {
  const t = useTranslation("verify");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const resendOtp = useSelector((state) => state.admin.resendOtp);
  const [skipRender, setSkipRender] = useState(true);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        if (props.forgotPassword && props.forgotPassword === 2) {
          dispatch(
            updateEmailStart({
              email: props.showVerify,
              verification_code: otp,
            })
          );
        } else {
          dispatch(
            emailVerifyStart({
              email: props.showVerify.email,
              remember: props.showVerify.remember,
              verification_code: otp,
            })
          );
        }
      } else {
        getErrorNotificationMessage("The verification code should be 6 digits.");
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  const handleresendOtp = () => {
    if (props.forgotPassword && props.forgotPassword === 2) {
      dispatch(
        updateEmailCodeStart({
          email: props.showVerify,
        })
      );
    } else {
      dispatch(
        resendOtpStart({
          email: props.showVerify.email,
          code_type: "email",
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender && 
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeShowVerify();
    }
    setSkipRender(false);
  }, [updateEmail]);

  return (
    <Modal
      className="modal-dialog-center payment-link-created-modal"
      size="md"
      centered
      show={props.showVerify}
      onHide={props.closeShowVerify}
    >
      <Modal.Body>
        <Button onClick={props.closeShowVerify} className="modal-close">
          <Image
            className="close-icon"
            src={window.location.origin + "/img/close-modal-icon.svg"}
          />
        </Button>
        <div className="add-bank-account-modal-sec">
          <div className="settlement-top-sec">
            <h3>
              {props.forgotPassword ? t("update_email_verification") : t("otp_verification")}
            </h3>
          </div>
          <Row className="align-items-center">
            <Col md={12}>
              <Formik
                initialValues={
                  props.edit
                    ? {
                        otp: props.addUPIModal,
                        user_upi_id: props.editId,
                      }
                    : {
                        otp: "",
                      }
                }
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <FORM onSubmit={handleSubmit}>
                    <div className="verification-item">
                      <h6>{t("enter_otp")}</h6>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        shouldAutoFocus={true}
                        separator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="verification-btn-sec">
                      <Button
                        type="button"
                        onClick={handleresendOtp}
                        className="snapgopay-auth-btn"
                        disabled={
                          emailVerify.buttonDisable ||
                          resendOtp.buttonDisable ||
                          updateEmail.buttonDisable ||
                          updateEmailCode.buttonDisable
                        }
                      >
                        {resendOtp.buttonDisable || updateEmailCode.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("resend_otp")
                        )}
                      </Button>
                      <Button
                        type="submit"
                        className="snapgopay-auth-btn"
                        disabled={
                          emailVerify.buttonDisable ||
                          resendOtp.buttonDisable ||
                          updateEmail.buttonDisable ||
                          updateEmailCode.buttonDisable
                        }
                      >
                        {emailVerify.buttonDisable || updateEmail.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("validate")
                        )}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OTPVerificationModal;

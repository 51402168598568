import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Dropdown, Image, Nav, Form, InputGroup, Modal } from "react-bootstrap";
import Select from "react-select";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { getErrorNotificationMessage, getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import Pagination from "../Helper/Pagination";
import { Field, Form as FORM, Formik } from "formik";
import ViewTransactionModal from "./ViewTransactionModal";
import TransactionStatusUpdateModal from "./TransactionStatusUpdateModal";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation } from "react-multi-lang";
import DatePicker from "react-multi-date-picker";
import { exportPayinTransactionsStart } from "../../store/slices/PaymentSlice";
import configuration from "react-global-configuration";
import {
  TRANSACTION_INITIATED,
  TRANSACTION_PROCESSING,
  TRANSACTION_SUCCESS,
  TRANSACTION_REJECTED,
  TRANSACTION_CANCELLED,
  TRANSACTION_FAILED,
  TRANSACTION_CHARGEBACK,
} from "../Constants/constant";

const InvoiceTransactionsIndex = (props) => {

  const t = useTranslation("transactions")
  const [modalShow, setModalShow] = React.useState(false)

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3d8b9c",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "price_hl", label: "Price High to Low" },
    { value: "price_lh", label: "Price Low to High" },
  ];

  const payment_type_options = [
    { value: "card", label: "Card" },
    { value: "upi", label: "UPI" },
    { value: "crypto", label: "Crypto" },
  ]

  const status_options = [
    { value: TRANSACTION_INITIATED, label: "Initiated" },
    { value: TRANSACTION_PROCESSING, label: "User Paid" },
    { value: TRANSACTION_SUCCESS, label: "Completed" },
    { value: TRANSACTION_REJECTED, label: "Rejected" },
    { value: TRANSACTION_CANCELLED, label: "Cancelled" },
    { value: TRANSACTION_FAILED, label: "Failed" },
    { value: TRANSACTION_CHARGEBACK, label: "Charged Back" },
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const profile = useSelector((state) => state.admin.profile);
  const transactionList = useSelector((state) => state.payment.transactionList);
  const [skipRender, setSkipRender] = useState(true);
  const [statusFilterStatus, setStatusFilterStatus] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    sort_by: searchParams.get("sort_by") ? searchParams.get("sort_by") : "",
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    type: searchParams.get("type") ? searchParams.get("type") : "",
    payment_type: "",
    status: searchParams.get("status") ? searchParams.get("status") : "",
    // invoice_links: 1,
  });
  const [acceptPayoutModal, setAcceptPayoutPaymentModal] = useState(false);
  const exportPayinTransactions = useSelector(state => state.payment.exportPayinTransactions)

  const formRef = React.useRef();
  const calenderRefs = useRef(null);
  const [close, setClose] = useState(false);
  const [value, setValue] = useState([null , null]);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });

  const closeAcceptPayoutPaymentModal = () => {
    setAcceptPayoutPaymentModal(false);
  };

  const handleDateChange = (values) => {
    if (values) {       
      setValue(values);
      formRef?.current?.setFieldValue("from_date", values && values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "");
      formRef?.current?.setFieldValue("to_date", values && values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "");
      setDateValues({
        from_date: values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "",
        to_date: values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "",
      })
    }
    else {
      setDateValues({
        from_date: "",
        to_date: "",
      })
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      })

    }
  }

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          ...filter,
          // paid_as: 2,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  // useEffect(() => {
  //   if (!skipRender) {
  //     setPage(searchParams.get("page") ? searchParams.get("page") : 1);
  //     setFilter({
  //       search_key: searchParams.get("search_key")
  //         ? searchParams.get("search_key")
  //         : "",
  //       status: searchParams.get("status") ? searchParams.get("status") : "",
  //     });
  //   }
  //   setSkipRender(false);
  // }, [
  //   searchParams.get("page"),
  //   searchParams.get("search_key"),
  //   searchParams.get("status"),
  // ]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("payment_type")
      ? `payment_type=${searchParams.get("payment_type")}&`
      : "";
    navigate(`/invoice-transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    let option = options.find(
      (option) => option.value == searchParams.get("sort_by")
    );
    if (option) {
      setFilterStatus(option);
    }
  }, [searchParams.get("sort_by")]);

  useEffect(() => {
    let status_option = status_options.find(
      (option) => option.value == searchParams.get("status")
    );
    if (status_option) {
      setStatusFilterStatus(status_option);
    }
  }, [searchParams.get("status")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"),searchParams.get("search_key")]);

  // useEffect(() => {
  //   let payment_type_option = payment_type_options.find(
  //     (option) => option.value == searchParams.get("payment_type")
  //   );
  //   if (payment_type_option) {
  //     setPaymentType(payment_type_option);
  //   }
  // }, [searchParams.get("payment_type")]);

  const getStatusClass = (status) => {
    switch (status) {
      case TRANSACTION_INITIATED:
        return "payment-initiated";
      case TRANSACTION_PROCESSING:
        return "payment-onhold";
      case TRANSACTION_SUCCESS:
        return "payment-paid";
      case TRANSACTION_REJECTED:
        return "payment-rejected";
      case TRANSACTION_CANCELLED:
        return "payment-cancelled";
      case TRANSACTION_FAILED: 
        return "payment-failed";
      case TRANSACTION_CHARGEBACK: 
        return "payment-chargeback";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case TRANSACTION_INITIATED:
        return "#5C9EEB";
      case TRANSACTION_PROCESSING:
        return "#FFCE22";
      case TRANSACTION_SUCCESS:
        return "#197E23";
      case TRANSACTION_REJECTED:
        return "#818181";
      case TRANSACTION_CANCELLED:
        return "#FF8A00";
      case TRANSACTION_FAILED:
        return "#FF6A6A";
      case TRANSACTION_CHARGEBACK:
        return "#dd5ceb";
      default:
        return "#5C9EEB";
    }
  };

  const handleExport = () => {
    dispatch(exportPayinTransactionsStart({
      ...filter,
      is_central: 1,
      from_date: dateValues.from_date,
      to_date: dateValues.to_date,
    }))
  }

  useEffect(() => {
    if (!skipRender && !exportPayinTransactions.buttonDisable && Object.keys(exportPayinTransactions.data).length > 0) {
      window.open(exportPayinTransactions.data.url, '_blank', 'noreferrer')
    }
    setSkipRender(false);
  }, [exportPayinTransactions])

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-box">
          <div className="payment-link-header-sec">
            <div className="create-payment-link-header-sec">
              {/* {!isInvoice && <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() =>{
                      setFilter({
                        ...filter,
                        type: "",
                      })
                      // navigate(`/transactions?type=payout`)
                    }}
                    active={filter.type === ""}
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() =>{
                      setFilter({
                        ...filter,
                        type: 1,
                      })
                      // navigate(`/transactions?type=payin`)
                    }}
                    active={filter.type === 1}
                  >
                    PayIn
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() =>{
                      setFilter({
                        ...filter,
                        type: 2,
                      })
                      // navigate(`/transactions?type=payout`)
                    }}
                    active={filter.type === 2}
                  >
                    Pay Out
                  </Nav.Link>
                </Nav.Item>
              </Nav>} */}
              <h3>{t("invoice_title")}</h3>
            </div>
            <div className="transactions-list-filter-sec">
              <Select
                options={status_options}
                className="select-payout"
                styles={customStyles}
                // menuIsOpen={true}
                isSearchable={false}
                isClearable
                value={statusFilterStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      status: selectedOption.value,
                    });
                    setStatusFilterStatus(selectedOption);
                    navigate(filter.search_key ? filter.sort_by ? `/invoice-transactions?search_key=${filter.search_key}&sort_by=${filter.sort_by}&status=${selectedOption.value}` : `/invoice-transactions?search_key=${filter.search_key}&status=${selectedOption.value}` : filter.sort_by ? `/invoice-transactions?sort_by=${filter.sort_by}&status=${selectedOption.value}` : `/invoice-transactions?status=${selectedOption.value}`);
                  } else {
                    const newFilter = { ...filter };
                    delete newFilter.status;
                    setFilter(newFilter);
                    navigate(filter.search_key ? filter.sort_by ? `/invoice-transactions?search_key=${filter.search_key}&sort_by=${filter.sort_by}` : `/invoice-transactions?search_key=${filter.search_key}` : filter.sort_by ? `/invoice-transactions?sort_by=${filter.sort_by}` : "/invoice-transactions");
                    setStatusFilterStatus(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">
                    {t("status_filter_placeholder")}
                  </div>
                }
              />
              <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                    from_date: "",
                    to_date: "",
                  }}
                  innerRef={formRef}
                  onSubmit={(val) =>  {
                    if(val.search_key){
                      setFilter({
                        ...filter,
                        search_key: val.search_key,
                        from_date: dateValues.from_date,
                        to_date: dateValues.to_date
                      });
                      navigate(
                        filter.status ? filter.sort_by ? `/invoice-transactions?search_key=${val.search_key}&sort_by=${filter.sort_by}&status=${filter.status}` : `/invoice-transactions?search_key=${val.search_key}&status=${filter.status}` : filter.sort_by ? `/invoice-transactions?search_key=${val.search_key}&sort_by=${filter.sort_by}` : `/invoice-transactions?search_key=${val.search_key}`
                      );
                    }
                  }}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form" style={{ display: "flex", gap: "1em" }}>
                      <Form.Group className={`d-flex date-sec ${close ? "date-close":""}`} >
                      <DatePicker
                        value={value}
                        style={{ padding: "1em" }}
                        placeholder="Select Date Range"
                        onChange={date => {
                          handleDateChange(date);
                        }}
                        range
                        numberOfMonths={2}
                        ref={calenderRefs}
                        maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                      >
                        <div className="d-flex justify-content-end" style={{ padding: "1em" }}>
                          <Button
                            className="snapgopay-datePicker-btn-cancel"
                            onClick={() => {
                              calenderRefs.current.closeCalendar();
                            }}
                          >
                            {t("close")}
                          </Button>
                          <Button
                            className="snapgopay-datePicker-btn"
                            onClick={() => {
                              if (values.from_date || values.to_date) {
                                setFilter({
                                  ...filter,
                                  skip: 12 * (page - 1),
                                  take: 12,
                                  from_date: dateValues.from_date,
                                  to_date: dateValues.to_date,
                                })
                                calenderRefs.current.closeCalendar();
                                setClose(true)
                              }
                              else
                              getErrorNotificationMessage("Please select date")
                            }}>
                            {t("apply")}
                          </Button>
                        </div>
                      </DatePicker>
                      {close ? (
                        <>
                          <button
                            type="button"
                            className="search-btn date-cancel"
                            onClick={() => {
                              // setValue([null , new Date()]);
                              setDateValues({
                                from_date: "",
                                to_date: "",
                              }); 
                              setValue([null , null]);
                              setClose(false)
                              setFilter({
                                ...filter,
                                from_date: "",
                                to_date: ""
                              });
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        </>
                      ) : null}
                    </Form.Group>
                      <InputGroup className="mb-0">
                      <Field
                        placeholder={t("search_placeholder")}
                        type="text"
                        className="form-control"
                        name="search_key"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            formRef.current.handleSubmit();
                          }
                        }} />
                      <InputGroup.Text id="basic-addon2">
                        {searchParams.get("search_key") && (
                          <button
                            type="button"
                            className="search-btn"
                            onClick={() => {
                              setFieldValue("search_key", "");
                              setFilter({
                                ...filter,
                                search_key: "",
                              });
                              navigate(
                                filter.status ? filter.sort_by ? `/invoice-transactions?sort_by=${filter.sort_by}&status=${filter.status}` : `/invoice-transactions?status=${filter.status}` : filter.sort_by ? `/invoice-transactions?sort_by=${filter.sort_by} ` : "/invoice-transactions"
                              );
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        )}
                      </InputGroup.Text>
                      <InputGroup.Text id="basic-addon2">
                        <button className="search-btn" type="submit">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#3E4351"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 513.749 513.749"
                            version="1.1"
                            viewBox="0 0 513.749 513.749"
                            xmlSpace="preserve"
                          >
                            <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                          </svg>
                        </button>
                      </InputGroup.Text>
                      </InputGroup>
                    </FORM>
                  )}
                </Formik>
              </div>
              {/* <Select
                options={payment_type_options}
                styles={customStyles}
                isSearchable={false}
                isClearable
                value={paymentType || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      payment_type: selectedOption.value,
                    });
                    setPaymentType(selectedOption);
                  } else {
                    setFilter({
                      ...filter,
                      payment_type: "",
                    });
                    setPaymentType(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                      width="20"
                      height="20"
                      stroke-width="1.5" stroke="currentColor" 
                      fill="none"
                      viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                    </svg>
                    {t("payment_type")}
                  </div>
                }
              /> */}
              <Select
                options={options}
                styles={customStyles}
                // menuIsOpen={true}
                isSearchable={false}
                isClearable
                value={filterStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      sort_by: selectedOption.value,
                    });
                    setFilterStatus(selectedOption);
                    navigate(
                      filter.search_key ? filter.status ? `/invoice-transactions?search_key=${filter.search_key}&status=${filter.status}&sort_by=${selectedOption.value}` : `/invoice-transactions?search_key=${filter.search_key}&sort_by=${selectedOption.value}` : filter.status ? `/invoice-transactions?status=${filter.status}&sort_by=${selectedOption.value}` : `/invoice-transactions?sort_by=${selectedOption.value}`
                    );
                  } else {
                    setFilter({
                      ...filter,
                      sort_by: "",
                    });
                    navigate(
                      filter.search_key ? filter.status ? `/invoice-transactions?search_key=${filter.search_key}&status=${filter.status}` : `/invoice-transactions?search_key=${filter.search_key}` : filter.status ? `/invoice-transactions?status=${filter.status} ` : "/invoice-transactions"
                    );
                    setFilterStatus(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#111"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 24a1 1 0 01-.6-.2l-4-3A1 1 0 019 20v-5.62L1.984 6.487A3.9 3.9 0 014.9 0h14.2a3.9 3.9 0 012.913 6.488L15 14.38V23a1 1 0 01-1 1zm-3-4.5l2 1.5v-7a1 1 0 01.253-.664l7.268-8.177A1.9 1.9 0 0019.1 2H4.9a1.9 1.9 0 00-1.421 3.158l7.269 8.178A1 1 0 0111 14z"></path>
                    </svg>
                    {t("filter_placeholder")}
                  </div>
                }
              />
               <Button
                type="button"
                className="snapgopay-payment-btn"
                onClick={handleExport}
                disabled={exportPayinTransactions.buttonDisable || (transactionList.loading || !Object.keys(transactionList.data).length > 0 || !transactionList.data.transactions.length > 0) }
              >
                {t("export_transactions")}
              </Button>
            </div>
          </div>
          <div className="recent-transaction-table-sec table-responsive">
            {transactionList.loading ? (
              [...Array(3)].map((i, key) => <Skeleton key={key} height={50} />)
            ) : Object.keys(transactionList.data).length > 0 ? (
              <React.Fragment>
                <Table>
                  <thead>
                    <tr>
                    <th>{t("sl_no")}</th>
                    <th>{t("transaction_id")}</th>
                    <th>{t("order_id")}</th>
                    <th>{t("mode")}</th>
                    <th>{t("type")}</th>
                    {profile.data.show_payin_ledger ? (
                      <th>{t("view.opening_balance")}</th>
                    ) : null}
                    <th>{t("view.requested_amount")}</th>
                    <th>{t("view.settlement_amount")}</th>
                    <th>{t("view.service_fee")}</th>
                    {profile.data.show_payin_ledger ? (
                      <th>{t("view.closing_balance")}</th>
                    ) : null}
                    <th>{t("created_date")}</th>
                    <th>{t("status")}</th>
                    <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList.data.transactions.length > 0 ? (
                      transactionList.data.transactions.map((transaction, i) => (
                          <tr key={i}>
                            <td>{(12 * (page-1)) + (i+1)}</td>
                            <td>{transaction.unique_id}</td>
                            <td>{transaction.order_id}</td>
                            <td>{transaction.type_formatted}</td>
                            <td>{transaction.payment_type_formatted}</td>
                            {profile.data.show_payin_ledger ? (
                              <td>{transaction.opening_balance}</td>
                            ) : null}
                            <td>{transaction.formatted_total}</td>
                            <td>{transaction.formatted_amount}</td>
                            <td>{configuration.get("configData.currency")} {transaction.admin_commission_amount + transaction.merchant_commission_amount}</td>
                            {profile.data.show_payin_ledger ? (
                              <td>{transaction.closing_balance}</td>
                            ) : null}
                            <td>{transaction.created_at}</td>
                            <td>
                              <div className={getStatusClass(transaction.status)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill={getStatusColor(transaction.status)}
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                </svg>
                                {transaction.status_formatted}
                              </div>
                            </td>
                            <td>
                              <div className="payment-link-dropdown">
                                <Dropdown>
                                  <div className="payment-link-icon-sec">
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 512 512"
                                        version="1.1"
                                        viewBox="0 0 512 512"
                                        xmlSpace="preserve"
                                      >
                                        <circle
                                          cx="458.667"
                                          cy="256"
                                          r="53.333"
                                        ></circle>
                                        <circle
                                          cx="256"
                                          cy="256"
                                          r="53.333"
                                        ></circle>
                                        <circle
                                          cx="53.333"
                                          cy="256"
                                          r="53.333"
                                        ></circle>
                                      </svg>
                                    </Dropdown.Toggle>
                                  </div>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setModalShow(transaction)} >
                                      <span>{t("view_btn")}</span>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <NoDataFound/>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {transactionList.data.total_transactions > 12 ?<div className="new-billings-pagination-sec">
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(transactionList.data.total_transactions / 12)}
                    handlePageClick={handlePageClick}
                  />
                </div>:null}
              </React.Fragment>
            ) : null}
            <div></div>
          </div>
        </div>
      </div>
      <ViewTransactionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {acceptPayoutModal && (
        <TransactionStatusUpdateModal
          acceptPayoutModal={acceptPayoutModal}
          closeAcceptPayoutPaymentModal={closeAcceptPayoutPaymentModal}
          setAcceptPayoutPaymentModal={setAcceptPayoutPaymentModal}
        />
      )}
    </>
  );
};

export default InvoiceTransactionsIndex;

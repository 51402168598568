import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bankAccountDeleteStart, bankAccountListStart, bankAccountStatusUpdateStart } from "../../store/slices/WithdrawSlice";
import Skeleton from "react-loading-skeleton";
import { ButtonLoader } from "../Helper/Loader";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation } from "react-multi-lang";

const BankAccountIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("bank_index");
  const profile = useSelector((state) => state.admin.profile);
  const bankAccountList = useSelector((state) => state.withdraw.bankAccountList);
  const bankAccountDelete = useSelector((state) => state.withdraw.bankAccountDelete);
  const bankAccountStatusUpdate = useSelector((state) => state.withdraw.bankAccountStatusUpdate);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(bankAccountListStart());
    }
  }, [profile]);

  return (
    <>
      <div className="bank-account-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
          <Link
            to="/bank-accounts/create"
            type="button"
            className="snapgopay-payment-btn"
          >
            {t("add_bank_account")}
          </Link>
        </div>
        <div className="bank-account-list-box">
          {bankAccountList.loading ? (
            [...Array(3)].map((i, key) => <Skeleton key={key} height={220} />)
          ) : Object.keys(bankAccountList.data).length > 0 ? (
            <React.Fragment>
              {bankAccountList.data.bank_accounts.length > 0
                ? bankAccountList.data.bank_accounts.map((account, i) => (
                    <div className={`bank-account-list-card ${account.is_default ? "bank-account-list-primary-card": ""}`} key={i}>
                      <div className="bank-account-list-item">
                        <div className="bank-account-list-header-sec">
                          <div className="bank-account-list-header-left-sec">
                            <h4>{account.account_holder_name}</h4>
                            <p>{account.bank_name.toUpperCase()}</p>
                          </div>
                          <div className="bank-account-list-header-right-sec">
                            {!account.is_default && 
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  <strong>{t("set_as_default_account")}</strong>{" "}
                                </Tooltip>
                              }>
                              <Button className="make-primary-btn"
                                onClick={()=> dispatch(bankAccountStatusUpdateStart({ bank_account_id : account.id }))}
                                disabled={bankAccountStatusUpdate.buttonDisable}
                                >
                                {bankAccountStatusUpdate.buttonDisable && (account.id === bankAccountStatusUpdate.data.bank_account_id) ? <ButtonLoader/> :<svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 22 16"
                                >
                                  <path
                                    fill="#FFB743"
                                    d="M1.971 11.422L.717 3.267a.68.68 0 011.08-.648l3.909 2.932a.934.934 0 001.338-.23l3.254-4.88a.869.869 0 011.445 0l3.255 4.88a.934.934 0 001.337.23l3.909-2.932a.68.68 0 011.08.648l-1.254 8.155H1.97zM19.126 15.947H2.914a.944.944 0 01-.943-.944V12.93H20.07v2.073a.944.944 0 01-.944.944z"
                                  ></path>
                                </svg>}
                              </Button>
                            </OverlayTrigger>
                            }
                            <Link className="edit-btn" to={`/bank-accounts/create/${account.id}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                viewBox="0 0 16 17"
                              >
                                <path
                                  stroke="#111"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13.536 6.296L6.112 13.72a2.667 2.667 0 01-1.886.78h-1.56A.666.666 0 012 13.834v-1.56a2.666 2.666 0 01.78-1.886l7.424-7.424a1.6 1.6 0 012.253 0l1.08 1.08a1.6 1.6 0 010 2.252zM9 4.17l3.333 3.334"
                                ></path>
                              </svg>
                            </Link>
                            <Button className="delete-btn"
                              disabled={bankAccountDelete.buttonDisable} 
                              onClick={()=> dispatch(bankAccountDeleteStart({ bank_account_id: account.id }))}>
                              {bankAccountDelete.buttonDisable && (account.id === bankAccountDelete.data.bank_account_id) ? <ButtonLoader/> : <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                viewBox="0 0 19 20"
                              >
                                <g
                                  stroke="#FF6A6A"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  clipPath="url(#clip0_43_3597)"
                                >
                                  <path d="M16.666 6.333H3.333M15 8.833l-.558 6.134A3.333 3.333 0 0111.108 18h-2.25a3.333 3.333 0 01-3.333-3.033L5 8.833M8.333 10.5V13M11.658 10.5V13M13.75 6.333h-7.5l.417-1.758A2.067 2.067 0 018.708 3h2.584a2.067 2.067 0 012.041 1.575l.417 1.758z"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_43_3597">
                                    <path
                                      fill="#fff"
                                      d="M0 0H19V19H0z"
                                      transform="translate(0 .5)"
                                    ></path>
                                  </clipPath>
                                </defs>
                              </svg>}
                            </Button>
                          </div>
                        </div>
                        <div className="bank-account-list-body-sec">
                          <div className="bank-account-list-account-number-sec">
                            <h4>XXXX XXXX XXXX {account.account_number.slice(-4)}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="bank-account-list-footer-sec">
                        <div className="bank-account-list-ifsc-code-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="none"
                            viewBox="0 0 21 21"
                          >
                            <path
                              stroke="#252525"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M2.833 18h15M4.517 10.5v5M10.342 10.5v5M16.184 10.5v5M3.417 6.05l5.358-2.675a3.492 3.492 0 013.15 0l5.35 2.667A1.033 1.033 0 0116.817 8H3.875a1.025 1.025 0 01-.458-1.95z"
                            ></path>
                          </svg>
                          <span>{account.ifsc_code}</span>
                        </div>
                        <div className="bank-account-list-type-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              stroke="#252525"
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                            ></path>
                          </svg>
                          <span>{account.account_type.charAt(0).toUpperCase() + account.account_type.slice(1) } {t("account")}</span>
                        </div>
                      </div>
                    </div>
                  ))
                :
                <React.Fragment>
                  <div></div>
                  <NoDataFound/>
                </React.Fragment>}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BankAccountIndex;

import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, Image } from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import Pagination from "../Helper/Pagination";
import DeletePaymentModal from "./DeletePaymentModal";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation } from "react-multi-lang";

const PaymentLinkIndex = (props) => {

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3d8b9c",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    // { value: "price_hl", label: "Price High to Low" },
    // { value: "price_lh", label: "Price Low to High" },
  ];

  const t = useTranslation("payment")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const paymentList = useSelector((state) => state.payment.paymentList);
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    sort_by: searchParams.get("sort_by") ? searchParams.get("sort_by") : "",
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        paymentListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"),searchParams.get("search_key")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("sort_by")
      ? `sort_by=${searchParams.get("sort_by")}&`
      : "";
    navigate(`/api-payment?${params}page=${selected + 1}`);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
      case 2:
        return "badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15";
      case 3:
        return "badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15";
      default:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
    }
  };

  const submit = (payment_link_id) => {
    // confirmAlert({
    //   title: 'Confirm to submit',
    //   message: 'Are you sure to delete this?',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => dispatch(paymentDeleteStart({
    //         payment_link_id: payment_link_id
    //       }))
    //     },
    //     {
    //       label: 'No',
    //     }
    //   ]
    // });
  };

  useEffect(() => {
    let option = options.find((option) => option.value == searchParams.get("sort_by"));
    if(option)
    {
      setFilterStatus(option)
    }
  }, [searchParams.get("sort_by")]);
  

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-box">
          <div className="payment-link-header-sec">
            <h3>{t("api_payment.title")}</h3>
            <div className="payment-link-header-right-sec">
              <Select
                options={options}
                styles={customStyles}
                // menuIsOpen={true}
                isSearchable={false}
                isClearable
                value={filterStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      sort_by: selectedOption.value,
                    });
                    setFilterStatus(selectedOption);
                    navigate(
                      `/api-payment?sort_by=${selectedOption.value}`
                    );
                  } else {
                    setFilter({
                      ...filter,
                      sort_by: "",
                    });
                    navigate("/api-payment");
                    setFilterStatus(null);
                  }
                }}
                placeholder={t("api_payment.filter_placeholder")}
              />
              <Link to="/api-payment/create" className="snapgopay-payment-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#111"
                    stroke="#111"
                    strokeWidth="0.5"
                    d="M11.457 14.097l-2.507 2.36c-1.53 1.4-3.936 1.245-5.375-.348-1.411-1.562-1.372-3.933.088-5.348l2.508-2.363a.767.767 0 00.018-1.079.753.753 0 00-1.07-.054L2.612 9.628c-2.11 1.988-2.165 5.381-.124 7.58 2.04 2.199 5.405 2.37 7.515.383l2.508-2.361a.768.768 0 000-1.08.753.753 0 00-1.051-.053h-.003 0zM17.757 2.795a5.451 5.451 0 00-3.73-1.764 5.087 5.087 0 00-3.783 1.376L7.732 4.77a.767.767 0 00-.018 1.079c.29.313.769.338 1.07.055l2.51-2.361a3.638 3.638 0 012.705-.986c2.1.108 3.772 1.913 3.736 4.03a3.719 3.719 0 01-1.158 2.653L14.07 11.6a.767.767 0 00-.018 1.08c.291.312.77.337 1.07.054l2.508-2.36c2.107-1.988 2.164-5.378.128-7.579z"
                  ></path>
                  <path
                    fill="#111"
                    stroke="#111"
                    strokeWidth="0.5"
                    d="M11.901 7.26L7.29 11.605a.766.766 0 00-.037 1.077.754.754 0 001.088.056l4.613-4.343a.768.768 0 00-.001-1.08.753.753 0 00-1.05-.053z"
                  ></path>
                </svg>
                {t("api_payment.create_button")} 
              </Link>
            </div>
          </div>
          <div className="recent-transaction-table-sec payment-link-table-sec table-responsive">
            {paymentList.loading ? (
              [...Array(3)].map((i, key) => (
                <Skeleton key={key} height={50} />
              ))
            ) : Object.keys(paymentList.data).length > 0 ? (
              <React.Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th>{t("api_payment.si_no")}</th>
                      <th>{t("api_payment.type")}</th>
                      <th>{t("api_payment.name")}</th>
                      <th>{t("api_payment.api_payment")}</th>
                      <th>{t("api_payment.created_date")}</th>
                      <th>{t("api_payment.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList.data.payment_links.length > 0 ? (
                      paymentList.data.payment_links.map((payment, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{payment.type == 1 ? t("api_payment.pay_in") : t("api_payment.pay_out")}</td>
                          <td>{payment.name}</td>
                          <td>
                            <div className="payment-link-card">
                              <div className="payment-link-left-sec">
                                {payment.api_url.slice(0, 25)}...{payment.api_url.slice(-15)}
                              </div>
                              <div className="payment-link-right-sec">
                                <CopyToClipboard
                                  text={payment.api_url}
                                  onCopy={() => getSuccessNotificationMessage(t("api_payment.url_copied"))} 
                                >
                                  <Button className="copy-btn">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      fill="#5C9EEB"
                                      height="20"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                    </svg>
                                    <span>{t("api_payment.copy")}</span> {/* Use the translation for the button text */}
                                  </Button>
                                </CopyToClipboard>
                              </div>
                            </div>
                          </td>
                          <td>{payment.created_at}</td>
                          <td>
                            <div className="payment-link-dropdown">
                              <Dropdown>
                                <div className="payment-link-icon-sec">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      x="0"
                                      y="0"
                                      enableBackground="new 0 0 512 512"
                                      version="1.1"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                    >
                                      <circle
                                        cx="458.667"
                                        cy="256"
                                        r="53.333"
                                      ></circle>
                                      <circle
                                        cx="256"
                                        cy="256"
                                        r="53.333"
                                      ></circle>
                                      <circle
                                        cx="53.333"
                                        cy="256"
                                        r="53.333"
                                      ></circle>
                                    </svg>
                                  </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => navigate(`/api-payment/show/${payment.id}`)}>
                                    <span>{t("api_payment.view")}</span> 
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => navigate(`/api-payment/create/${payment.id}`)}>
                                    <span>{t("api_payment.edit")}</span> 
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => setDeletePaymentModal(payment.id)}>
                                    <span>{t("api_payment.delete")}</span> 
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {paymentList.data.total_payment_links > 12 ? <div className="new-billings-pagination-sec">
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(
                      paymentList.data.total_payment_links / 12
                    )}
                    handlePageClick={handlePageClick}
                  />
                </div>: null}
              </React.Fragment>
            ) : null}
            <div></div>
          </div>
        </div>
      </div>
      {deletePaymentModal && (
        <DeletePaymentModal
          deletePaymentModal={deletePaymentModal}
          closeDeletePaymentModal={() => setDeletePaymentModal(false)}
          setDeletePaymentModal={setDeletePaymentModal}
        />
      )}
    </>
  );
};

export default PaymentLinkIndex;

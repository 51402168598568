import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import CopyToClipboard from "react-copy-to-clipboard";
import { Form, Button, InputGroup, Image } from "react-bootstrap";
import "./PaymentLayout.css";
import configuration from "react-global-configuration";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { transactionUpdateStart } from "../../store/slices/PaymentSlice";
import PaymentCancel from "./PaymentCancel";
import { transactionInitiateStart, checkPaymentMethodClear } from "../../store/slices/TraderSlice";
import { useTranslation } from "react-multi-lang";

const PayInPayment = (props) => {
  const t = useTranslation("payin_payment");
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const transactionView = useSelector((state) => state.payment.transactionView);
  const transactionUpdate = useSelector(
    (state) => state.payment.transactionUpdate
  );
  const transactionInitiate = useSelector(
    (state) => state.trader.transactionInitiate
  );
  const [transactionUTR, setTransactionUTR] = useState("");

  const validationSchema = Yup.object().shape({
    utr_number: Yup.string().when("payment_type", {
      is: "upi",
      then: Yup.string().required(t("utr_id.required")),
    }),
    transaction_hash: Yup.string().when("payment_type", {
      is: "crypto",
      then: Yup.string().required(t("transaction_hash.required")),
    }),
  });

  const handleSubmit = (values) => {
    let data = {};
    if (files.length > 0) {
      data = { ...data, payment_picture: files[0] };
    }
    if (values.payment_type == "inr") {
      transactionView.data.transaction.inr_type == "UPI"
        ? (data = { ...data, utr_number: values.utr_number })
        : (data = { ...data, payment_id: values.utr_number });
      setTransactionUTR(values.utr_number);
      dispatch(
        transactionInitiateStart({
          ...data,
          transaction_unique_id: transactionView.data.transaction.unique_id,
          payment_type: values.payment_type,
          order_id: transactionView.data.transaction.order_id,
        })
      );
      return;
    } else {
      data = { ...data, transaction_hash: values.transaction_hash };
    }
    dispatch(
      transactionUpdateStart({
        step: 2,
        transaction_unique_id: transactionView.data.transaction.unique_id,
        payment_type: values.payment_type,
        ...data,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !transactionInitiate.loading &&
      Object.keys(transactionInitiate.data).length > 0
    ) {
      let data = {
        transaction_unique_id:
        transactionView.data.transaction.unique_id,
        payment_type: transactionView.data.transaction.payment_type,
      }
      files[0] && (data = { ...data, payment_picture: files[0] });
      dispatch(
        transactionUpdateStart(
          transactionView.data.transaction.inr_type == "UPI"
            ? {
                step: 2,
                ...data,
                upi_id: props.paymentData.payment_details.upi_id,
                utr_number: transactionUTR,
                ...transactionInitiate.data,
              }
            : {
                step: 2,
                ...data,
                account_number:
                  props.paymentData.payment_details.account_number,
                payment_id: transactionUTR,
                ...transactionInitiate.data,
              }
        )
      );
    }
  }, [transactionInitiate]);

  const handleBack = () => {
    localStorage.removeItem("qr_code")
    dispatch(checkPaymentMethodClear())
    props.setStep(1)
  };

  useEffect(() => {
    if (
      !skipRender &&
      !transactionUpdate.loading &&
      Object.keys(transactionUpdate.data).length > 0
    ) {
      localStorage.setItem("qr_code", "");
      props.setStep(3);
    }
    setSkipRender(false);
  }, [transactionUpdate]);

  return (
    <>
      <div className="snapgopay-payment-form-wrap">
        <div className="snapgopay-form-wrap-top">
          <div className="form-heading">
            <a onClick={() => handleBack()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="11"
                fill="none"
                viewBox="0 0 7 11"
              >
                <path
                  fill="#090830"
                  fillRule="evenodd"
                  d="M5.563 10.776L.17 5.873a.494.494 0 010-.746L5.563.224a.903.903 0 011.19 0 .718.718 0 010 1.082L2.14 5.5l4.614 4.194c.328.299.328.783 0 1.082a.903.903 0 01-1.19 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <h2>{t("heading")}</h2>
          </div>
          <div className="upi-id-main-wrap">
            {transactionView.data.transaction.payment_type == "inr" ? (
              <span>
                {transactionView.data.transaction.inr_type == "UPI"
                  ? "UPI ID"
                  : "Bank Details"}
              </span>
            ) : (
              <span>{t("wallet_address")}</span>
            )}
            <div className="upi-id-info">
              {transactionView.data.transaction.payment_type == "inr" ? (
                transactionView.data.transaction.inr_type == "UPI" ? (
                  <div className="upi-id-sec">
                    {props.paymentData.payment_details.upi_id}
                  </div>
                ) : (
                  <div className="bank-account-info">
                    <div>
                      <p>{t("beneficiary_name")}</p>
                      <span>
                        {props.paymentData.payment_details.account_holder_name}
                      </span>
                      <p>{t("bank_name")}</p>
                      <span>{props.paymentData.payment_details.bank_name}</span>
                      <p> {t("account_type")}</p>
                      <span>
                        {props.paymentData.payment_details.account_type}
                      </span>
                    </div>
                    <div>
                      <p> {t("account_number")} </p>
                      <span>
                        {props.paymentData.payment_details.account_number}
                      </span>
                      <p>{t("ifsc_code")}</p>
                      <span>{props.paymentData.payment_details.ifsc_code}</span>
                    </div>
                  </div>
                )
              ) : (
                configuration.get("configData.admin_wallet_address")
              )}
              <div className="upi-id-copy-pad">
                {["inr", "crypto"].includes(
                  transactionView.data.transaction.payment_type
                ) && transactionView.data.transaction.inr_type == "UPI" ? (
                  <CopyToClipboard
                    text={
                      transactionView.data.transaction.inr_type == "UPI"
                        ? props.paymentData.payment_details.upi_id
                        : configuration.get("configData.admin_wallet_address")
                    }
                    onCopy={() =>
                      getSuccessNotificationMessage(
                        transactionView.data.transaction.inr_type == "UPI"
                          ? "UPI ID copied to clipboard!"
                          : "Wallet Address copied to clipboard!"
                      )
                    }
                  >
                    <Button type="submit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="20"
                        fill="none"
                        viewBox="0 0 19 20"
                      >
                        <g
                          stroke="#5C9EEB"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          clipPath="url(#clip0_73_2904)"
                        >
                          <path d="M13.459 5.323H9.5A3.167 3.167 0 006.333 8.49v3.958A3.167 3.167 0 009.5 15.615h3.959a3.167 3.167 0 003.166-3.167V8.49a3.167 3.167 0 00-3.166-3.167z"></path>
                          <path d="M12.667 5.324v-.792A3.167 3.167 0 009.5 1.365H5.542a3.167 3.167 0 00-3.167 3.167V8.49a3.166 3.166 0 003.167 3.167h.791"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_73_2904">
                            <path
                              fill="#fff"
                              d="M0 0H19V19H0z"
                              transform="translate(0 .5)"
                            ></path>
                          </clipPath>
                        </defs>
                      </svg>{" "}
                    {t("copy")}
                    </Button>
                  </CopyToClipboard>
                ) : null}
              </div>
            </div>
          </div>
          {!(
            transactionView.data.transaction.payment_type == "inr" &&
            transactionView.data.transaction.inr_type != "UPI"
          ) ? (
            <div className="qr-code-main-wrapped">
              <p>{t("scan_screenshot")}</p>
              <Image
                className="qr-bar"
                src={`${localStorage.getItem("qr_code")}?${new Date()}`}
                type="image/png"
              />
              {transactionView.data.transaction.payment_type == "inr" ? (
                <div className="amount-pay-sec">
                  {transactionView.data.transaction.formatted_total}
                </div>
              ) : (
                <div className="amount-pay-sec">
                  {/* {transactionView.data.transaction.formatted_amount} */}
                  {transactionUpdate.data.token_type}{" "}
                  {transactionUpdate.data.token}
                  <div className="upi-id-copy-pad">
                    <CopyToClipboard
                      text={transactionUpdate.data.token}
                      onCopy={() =>
                        getSuccessNotificationMessage(
                          "Token amount copied to clipboard!"
                        )
                      }
                    >
                      <Button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="20"
                          fill="none"
                          viewBox="0 0 19 20"
                        >
                          <g
                            stroke="#5C9EEB"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            clipPath="url(#clip0_73_2904)"
                          >
                            <path d="M13.459 5.323H9.5A3.167 3.167 0 006.333 8.49v3.958A3.167 3.167 0 009.5 15.615h3.959a3.167 3.167 0 003.166-3.167V8.49a3.167 3.167 0 00-3.166-3.167z"></path>
                            <path d="M12.667 5.324v-.792A3.167 3.167 0 009.5 1.365H5.542a3.167 3.167 0 00-3.167 3.167V8.49a3.166 3.166 0 003.167 3.167h.791"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_73_2904">
                              <path
                                fill="#fff"
                                d="M0 0H19V19H0z"
                                transform="translate(0 .5)"
                              ></path>
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        {t("copy")}
                      </Button>
                    </CopyToClipboard>
                  </div>
                  {/* <span>= {transactionView.data.token} {transactionView.data.token_type}</span> */}
                </div>
              )}
              {transactionView.data.transaction.payment_type == "inr" &&
              transactionView.data.transaction.inr_type == "UPI" ? (
                <div className="payment-method-sec">
                  <a href={`tez://upi/pay?pa=${props.paymentData.payment_details.upi_id}&pn=${props.paymentData.order_id}&mc=0000&tid=1111&tr=2222&tn=${props.paymentData.order_id}&am=${transactionView.data.transaction.total}&cu=INR`}>
                    <img
                      className="payment-icon"
                      src={
                        window.location.origin +
                        "/img/payment-type/gpay-icon.svg"
                      }
                      alt="Google Pay"
                    />
                  </a>
                  <a href={`phonepe://pay?pa=${props.paymentData.payment_details.upi_id}&pn=${props.paymentData.order_id}&mc=0000&tid=1111&tr=2222&tn=${props.paymentData.order_id}&am=${transactionView.data.transaction.total}&cu=INR`}>
                    <img
                      className="payment-icon"
                      src={
                        window.location.origin +
                        "/img/payment-type/phonepe-icon.svg"
                      }
                      alt="PhonePe"
                    />
                  </a>
                  <a href={`paytmmp://pay?pa=${props.paymentData.payment_details.upi_id}&pn=${props.paymentData.order_id}&mc=0000&tid=1111&tr=2222&tn=${props.paymentData.order_id}&am=${transactionView.data.transaction.total}&cu=INR`}>
                    <img
                      className="payment-icon"
                      src={
                        window.location.origin +
                        "/img/payment-type/paytm-icon.svg"
                      }
                      alt="Paytm"
                    />
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="snapgopay-form-wrap-bottom">
          <Formik
            initialValues={{
              utr_number: "",
              transaction_hash: "",
              payment_type: transactionView.data.transaction.payment_type,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM>
                <div className="snapgopay-file-sec">
                  {transactionView.data.transaction.payment_type == "inr" ? (
                    <>
                      <Form.Label>
                        {transactionView.data.transaction.inr_type == "UPI"
                          ? t("utr_id.name")
                          :t("payment_id")}
                      </Form.Label>
                      <Form.Group className="mb-3">
                        <Field
                          name="utr_number"
                          type="text"
                          className="form-control"
                          value={values.utr_number}
                          autoFocus={true}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="utr_number"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Label>{t("transaction_hash.name")} </Form.Label>
                      <Form.Group className="mb-3">
                        <Field
                          name="transaction_hash"
                          type="text"
                          className="form-control"
                          value={values.transaction_hash}
                          autoFocus={true}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="transaction_hash"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </>
                  )}
                  {files.length <= 0 && (
                    <Dropzone
                      useFsAccessApi={false}
                      onDrop={(acceptedFiles) =>
                        setFiles(
                          acceptedFiles.map((file) =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            })
                          )
                        )
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="utr-file-overlay">
                              <p>
                                {" "}
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    fill="none"
                                    viewBox="0 0 26 26"
                                  >
                                    <path
                                      fill="#5C9EEB"
                                      d="M19.055 9.128a6.243 6.243 0 00-12.11-.007 6.25 6.25 0 00.586 12.473h2.344a.781.781 0 000-1.563H7.531a4.688 4.688 0 01-.027-9.375.812.812 0 00.86-.667 4.68 4.68 0 019.267 0 .843.843 0 00.838.667 4.687 4.687 0 110 9.375h-2.344a.781.781 0 100 1.563h2.344a6.25 6.25 0 00.586-12.466z"
                                    ></path>
                                    <path
                                      fill="#5C9EEB"
                                      d="M16.354 15.896a.781.781 0 001.105-1.105l-3.906-3.906a.781.781 0 00-1.105 0l-3.906 3.906a.781.781 0 001.104 1.105l2.573-2.573v9.833a.781.781 0 101.562 0v-9.833l2.573 2.573z"
                                    ></path>
                                  </svg>
                                </span>{" "}
                              {t("upload_screenshot")}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  )}
                  {files.length > 0 &&
                    files.map((file) => (
                      <div className="screenshot">
                        <Button onClick={() => setFiles([])}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.293 16.707a1 1 0 001.414 0L12 13.414l3.293 3.293a1 1 0 001.414-1.414L13.414 12l3.293-3.293a1 1 0 10-1.414-1.414L12 10.586 8.707 7.293a1 1 0 10-1.414 1.414L10.586 12l-3.293 3.293a1 1 0 000 1.414z"></path>
                          </svg>
                        </Button>
                        <Image src={file.preview} />
                      </div>
                    ))}
                </div>
                <div className="form-wrap-btn">
                  <Button
                    className="btn-cancel"
                    type="button"
                    onClick={handleShow}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-next"
                    type="submit"
                    disabled={
                      transactionUpdate.buttonDisable ||
                      transactionInitiate.buttonDisable
                    }
                  >
                    {transactionUpdate.buttonDisable ||
                    transactionInitiate.buttonDisable
                      ? "Loading"
                      : "Submit"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          {show && <PaymentCancel show={show} handleClose={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default PayInPayment;

import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Dropdown,
  Row,
  Tab,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import ViewPayoutModal from "./ViewPayoutModal";
import 'react-datepicker/dist/react-datepicker.css';
import RequestPayoutModal from "./RequestDepositPayoutModal";
import { useDispatch, useSelector } from "react-redux";
import { walletStart, payoutDepositStart, exportDepositStart, setActiveChatSupport, chatSupportUnreadListSuccess } from "../../store/slices/PayoutSlice";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import NoDataFound from "../Helper/NoDataFound";
import Pagination from "../Helper/Pagination";
import { useTranslation } from "react-multi-lang";
import DatePicker from "react-multi-date-picker";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import ChatWidget from "./ChatWidget";
import * as Yup from "yup";
import {
  PAYOUT_DEPOSIT_INITIATED,
  PAYOUT_DEPOSIT_INPROGRESS,
  PAYOUT_DEPOSIT_COMPLETED,
  PAYOUT_DEPOSIT_FAILED,
} from "../Constants/constant";

const DepositPayoutIndex = (props) => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("deposit");
  const [searchParams] = useSearchParams();
  const depositList = useSelector(state => state.payout.payoutDeposit);
  const exportDeposit = useSelector(state => state.payout.exportDeposit);
  const [value, setValue] = useState([null , null]);
  const userWallet = useSelector(state => state.payout.wallet)
  const profile = useSelector(state => state.admin.profile);
  const [requestPayout, setRequestPayout] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [chatWidget, setChatWidget] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState({
    is_central: 1,
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1);
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  })

  const closeRequestPayoutModal = () => {
    setRequestPayout(false);
  };

  const options = [
    { value: PAYOUT_DEPOSIT_INITIATED, label: "Initiated" },
    { value: PAYOUT_DEPOSIT_INPROGRESS, label: "Inprogress " },
    { value: PAYOUT_DEPOSIT_COMPLETED, label: "Completed" },
    { value: PAYOUT_DEPOSIT_FAILED, label: "Failed" },
  ]

  const handleDateChange = (values) => {
    if (values) {
            
      setValue(values);
      formRef?.current?.setFieldValue("from_date", values && values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "");
      formRef?.current?.setFieldValue("to_date", values && values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "");
      setDateValues({
        from_date: values.length > 0 ? JSON.stringify(new Date(values[0])).slice(1, 11) : "",
        to_date: values.length > 1 ? JSON.stringify(new Date(values[1])).slice(1, 11) : "",
      })
    }
    else {
      setDateValues({
        from_date: "",
        to_date: "",
      })
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      })

    }
  }

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(walletStart());
    }
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        payoutDepositStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, filter, page]);

  const handleExport = () => {

    dispatch(exportDepositStart({
      ...filter,
      is_central: 1,
      from_date: dateValues.from_date,
      to_date: dateValues.to_date,
    }))
  }

  const getStatusClass = (status) => {
    switch (status) {
      case PAYOUT_DEPOSIT_INITIATED:
        return "payment-initiated";
      case PAYOUT_DEPOSIT_INPROGRESS:
        return "payment-onhold";
      case PAYOUT_DEPOSIT_COMPLETED:
        return "payment-paid";
      case PAYOUT_DEPOSIT_FAILED:
        return "payment-cancelled";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case PAYOUT_DEPOSIT_INITIATED:
        return "#5C9EEB";
      case PAYOUT_DEPOSIT_INPROGRESS:
        return "#FFCE22";
      case PAYOUT_DEPOSIT_COMPLETED:
        return "#197E23";
      case PAYOUT_DEPOSIT_FAILED:
        return "#FF8A00";
      default:
        return "#5C9EEB";
    }
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/deposit-payout?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (!skipRender && !exportDeposit.buttonDisable && Object.keys(exportDeposit.data).length > 0) {
      window.open(exportDeposit.data.url, '_blank', 'noreferrer')
    }
    setSkipRender(false);
  }, [exportDeposit])


  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3d8b9c",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  return (
    <>
      <div className="settlement-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
          <Button
            type="button"
            className="snapgopay-payment-btn"
            onClick={() => setRequestPayout(true)}
          >
            {t("request_deposit")}
          </Button>
        </div>
        <div className="settlement-header-sec">
          <div className="settlement-header-box">
            {userWallet.loading ? (
              [...Array(3)].map((i, key) => <Skeleton key={key} height={100} />)
            ) : Object.keys(userWallet.data).length > 0 &&
              Object.keys(userWallet.data.wallet).length > 0 ? (
              <React.Fragment>
                <div className="settlement-header-card">
                  <div className="settlement-header-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="none"
                      viewBox="0 0 34 34"
                    >
                      <path
                        fill="#2F8C86"
                        fillOpacity="0.45"
                        d="M22.808 11.294l1.838 1.114a.997.997 0 101.033-1.706l-.177-.108.278-.036a.997.997 0 10-.256-1.978s-3.119.465-3.247.504c.09.848.275 1.57.531 2.21zM24.117 16.422c-1.416-1.468-2.866-2.974-3.527-5.483H13.41c-.66 2.509-2.11 4.015-3.526 5.483-1.805 1.732-3.385 4.177-3.385 7.137.56 13.927 20.45 13.915 21.004 0 0-2.96-1.58-5.405-3.385-7.137zm-6.09 11.669v.095a.998.998 0 01-1.995 0v-.094a3.048 3.048 0 01-1.815-1.697.998.998 0 011.837-.777 1.057 1.057 0 10.976-1.47 3.056 3.056 0 01-3.053-3.052c0-1.334.86-2.47 2.055-2.885v-.146a.997.997 0 111.995 0v.144c.417.144.802.379 1.127.694a.997.997 0 11-1.389 1.433 1.059 1.059 0 10-.736 1.818 3.056 3.056 0 013.053 3.052c0 1.334-.86 2.47-2.055 2.885zM22.785 2.937c-.45-1.112-1.474-1.795-2.742-1.827h-.025c-.213 0-.423.022-.627.065C18.88.384 17.938-.007 17 0c-.925.007-1.845.402-2.342 1.183a2.9 2.9 0 00-.697-.078c-1.255.01-2.311.722-2.756 1.86-.485 1.242-.145 2.7.846 3.63.741.694 1.33 1.763 1.599 2.35h6.718a8.33 8.33 0 011.618-2.378c.976-.99 1.29-2.415.799-3.63z"
                      ></path>
                    </svg>
                  </div>
                  <div className="settlement-header-info-sec">
                    <p>{t("success_deposit_amount")}</p>
                    <h3>{userWallet.data.success_deposit_amount}</h3>
                  </div>
                </div>
                <div className="settlement-header-card">
                  <div className="settlement-header-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="none"
                      viewBox="0 0 34 34"
                    >
                      <path
                        fill="#2F8C86"
                        fillOpacity="0.45"
                        d="M22.808 11.294l1.838 1.114a.997.997 0 101.033-1.706l-.177-.108.278-.036a.997.997 0 10-.256-1.978s-3.119.465-3.247.504c.09.848.275 1.57.531 2.21zM24.117 16.422c-1.416-1.468-2.866-2.974-3.527-5.483H13.41c-.66 2.509-2.11 4.015-3.526 5.483-1.805 1.732-3.385 4.177-3.385 7.137.56 13.927 20.45 13.915 21.004 0 0-2.96-1.58-5.405-3.385-7.137zm-6.09 11.669v.095a.998.998 0 01-1.995 0v-.094a3.048 3.048 0 01-1.815-1.697.998.998 0 011.837-.777 1.057 1.057 0 10.976-1.47 3.056 3.056 0 01-3.053-3.052c0-1.334.86-2.47 2.055-2.885v-.146a.997.997 0 111.995 0v.144c.417.144.802.379 1.127.694a.997.997 0 11-1.389 1.433 1.059 1.059 0 10-.736 1.818 3.056 3.056 0 013.053 3.052c0 1.334-.86 2.47-2.055 2.885zM22.785 2.937c-.45-1.112-1.474-1.795-2.742-1.827h-.025c-.213 0-.423.022-.627.065C18.88.384 17.938-.007 17 0c-.925.007-1.845.402-2.342 1.183a2.9 2.9 0 00-.697-.078c-1.255.01-2.311.722-2.756 1.86-.485 1.242-.145 2.7.846 3.63.741.694 1.33 1.763 1.599 2.35h6.718a8.33 8.33 0 011.618-2.378c.976-.99 1.29-2.415.799-3.63z"
                      ></path>
                    </svg>
                  </div>
                  <div className="settlement-header-info-sec">
                    <p>{t("initiated_deposit_amount")}</p>
                    <h3>{userWallet.data.initiated_deposit_amount}</h3>
                  </div>
                </div>
                <div className="settlement-header-card">
                  <div className="settlement-header-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="none"
                      viewBox="0 0 34 34"
                    >
                      <path
                        fill="#2F8C86"
                        fillOpacity="0.45"
                        d="M22.808 11.294l1.838 1.114a.997.997 0 101.033-1.706l-.177-.108.278-.036a.997.997 0 10-.256-1.978s-3.119.465-3.247.504c.09.848.275 1.57.531 2.21zM24.117 16.422c-1.416-1.468-2.866-2.974-3.527-5.483H13.41c-.66 2.509-2.11 4.015-3.526 5.483-1.805 1.732-3.385 4.177-3.385 7.137.56 13.927 20.45 13.915 21.004 0 0-2.96-1.58-5.405-3.385-7.137zm-6.09 11.669v.095a.998.998 0 01-1.995 0v-.094a3.048 3.048 0 01-1.815-1.697.998.998 0 011.837-.777 1.057 1.057 0 10.976-1.47 3.056 3.056 0 01-3.053-3.052c0-1.334.86-2.47 2.055-2.885v-.146a.997.997 0 111.995 0v.144c.417.144.802.379 1.127.694a.997.997 0 11-1.389 1.433 1.059 1.059 0 10-.736 1.818 3.056 3.056 0 013.053 3.052c0 1.334-.86 2.47-2.055 2.885zM22.785 2.937c-.45-1.112-1.474-1.795-2.742-1.827h-.025c-.213 0-.423.022-.627.065C18.88.384 17.938-.007 17 0c-.925.007-1.845.402-2.342 1.183a2.9 2.9 0 00-.697-.078c-1.255.01-2.311.722-2.756 1.86-.485 1.242-.145 2.7.846 3.63.741.694 1.33 1.763 1.599 2.35h6.718a8.33 8.33 0 011.618-2.378c.976-.99 1.29-2.415.799-3.63z"
                      ></path>
                    </svg>
                  </div>
                  <div className="settlement-header-info-sec">
                    <p>{t("payout_balance")}</p>
                    <h3>{userWallet.data.wallet_balance}</h3>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className="settlement-body-sec">
          <div className="payment-link-header-sec">
            <p style={{ fontWeight: "600", fontSize: "1.1em", marginBottom: "0" }}>{t("deposit_history")}</p>
          </div>
          <div className="transactions-list-filter-sec">

            <Select
              options={options}
              className="select-payout"
              styles={customStyles}
              // menuIsOpen={true}
              isSearchable={false}
              isClearable
              value={filterStatus || null}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilter({
                    ...filter,
                    status: selectedOption.value,

                  });
                  setFilterStatus(selectedOption);
                  navigate(filter.search_key ? `/deposit-payout?search_key=${filter.search_key}&status=${selectedOption.value}` : `/deposit-payout?status=${selectedOption.value}`);
                } else {
                  const newFilter = { ...filter };
                  delete newFilter.status;
                  setFilter(newFilter);

                  navigate(filter.search_key ? `/deposit-payout?search_key=${filter.search_key}` : "/deposit-payout");
                  setFilterStatus(null);
                }
              }}
              placeholder={
                <div className="placeholder-flex">
                  {t("filter_placeholder")}
                </div>
              }
            />

            <div className="header-search">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                  sort_by: searchParams.get("sort_by")
                    ? searchParams.get("sort_by")
                    : filter.sort_by,
                  from_date: "",
                  to_date: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {

                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                    from_date: dateValues.from_date,
                    to_date: dateValues.to_date
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/deposit-payout?search_key=${val.search_key}&sort_by=${searchParams.get("sort_by")}`
                      : `/deposit-payout?search_key=${val.search_key}`, { state: { value: value } }
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <FORM className="form" style={{ display: "flex", gap: "1em" }}>
                    <Form.Group className={`d-flex date-sec ${close ? "date-close":""}`} >
                      <DatePicker
                        value={value}
                        style={{ padding: "1em" }}
                        placeholder="Select Date Range"
                        onChange={date => {
                          handleDateChange(date);
                        }}
                        range
                        numberOfMonths={2}
                        ref={calenderRefs}
                        maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                      >
                        <div className="d-flex justify-content-end" style={{ padding: "1em" }}>
                          <Button
                            className="snapgopay-datePicker-btn-cancel"
                            onClick={() => {
                              calenderRefs.current.closeCalendar();
                            }}
                          >
                            {t("close")}
                          </Button>
                          <Button
                            className="snapgopay-datePicker-btn"
                            onClick={() => {
                              if (values.from_date || values.to_date) {
                                setFilter({
                                  ...filter,
                                  skip: 12 * (page - 1),
                                  take: 12,
                                  from_date: dateValues.from_date,
                                  to_date: dateValues.to_date,
                                })
                                calenderRefs.current.closeCalendar();
                                setClose(true)
                              }
                              else
                              getErrorNotificationMessage("Please select date")
                            }}>
                            {t("apply")}
                          </Button>
                        </div>
                      </DatePicker>
                      {close ? (
                        <>
                          <button
                            type="button"
                            className="search-btn date-cancel"
                            onClick={() => {
                              // setValue([null , new Date()]);
                              setDateValues({
                                from_date: "",
                                to_date: "",
                              }); 
                              setValue([null , null]);
                              setClose(false)
                              setFilter({
                                ...filter,
                                from_date: "",
                                to_date: ""
                              });
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        </>
                      ) : null}
                    </Form.Group>
                    <InputGroup className="mb-0">
                      <Field
                        placeholder={t("search_placeholder")}
                        type="text"
                        className="form-control"
                        name="search_key"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            formRef.current.handleSubmit();
                          }
                        }} />
                      <InputGroup.Text id="basic-addon2">
                        {searchParams.get("search_key") && (
                          <button
                            type="button"
                            className="search-btn"
                            onClick={() => {
                              setFieldValue("search_key", "");
                              setFilter({
                                ...filter,
                                search_key: "",
                                from_date: dateValues.from_date,
                                to_date: dateValues.to_date,


                              });
                              navigate(
                                searchParams.get("sort_by")
                                  ? `/deposit-payout?sort_by=${searchParams.get(
                                    "sort_by"
                                  )}`
                                  : `/deposit-payout`
                              );
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        )}
                      </InputGroup.Text>
                      <InputGroup.Text id="basic-addon2">
                        <button className="search-btn" type="submit">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#3E4351"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 513.749 513.749"
                            version="1.1"
                            viewBox="0 0 513.749 513.749"
                            xmlSpace="preserve"
                          >
                            <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                          </svg>
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </FORM>
                )}
              </Formik>
            </div>
            <Button
              type="button"
              className="snapgopay-payment-btn"
              onClick={handleExport}
              disabled={exportDeposit.buttonDisable || (depositList.loading || !Object.keys(depositList.data).length > 0 || !depositList.data.payout_deposits.length > 0) }
            >
              {t("export_deposits")}
            </Button>
          </div>
          <div className="settlement-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <Row>
                <Col sm={12}>
                  <div className="settlement-tab-content">
                    <div className="settlement-table-sec table-responsive">
                      {depositList.loading ? (
                        [...Array(3)].map((i, key) => (
                          <Skeleton key={key} height={50} />
                        ))
                      ) : Object.keys(depositList.data).length > 0 ? (
                        <React.Fragment>
                          <Table>
                            <thead>
                              <tr>
                                <th>{t("deposit_table.si_no")}</th>
                                <th>{t("deposit_table.order_id")}</th>
                                <th>{t("deposit_table.amount")}</th>
                                <th>{t("deposit_table.account_number")}</th>
                                <th>{t("deposit_table.utr_number")}</th>
                                <th>{t("deposit_table.requested_date")}</th>
                                <th>{t("deposit_table.status")}</th>
                                <th>{t("deposit_table.action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {depositList.data.payout_deposits.length >
                                0 ? (
                                depositList.data.payout_deposits.map(
                                  (deposit, i) => (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{deposit.order_id}</td>
                                      <td>{deposit.amount_formatted}</td>
                                      <td>{deposit.payout_bank.account_number}</td>
                                      <td>{deposit.utr_number}</td>
                                      <td>{deposit.created_at}</td>
                                      <td>
                                        <div className="settlement-status-cell">
                                          <div
                                            className={getStatusClass(
                                              deposit.status
                                            )}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10"
                                              height="10"
                                              fill={getStatusColor(
                                                deposit.status
                                              )}
                                              data-name="Layer 1"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                            </svg>
                                            {deposit.status_formatted}
                                          </div>
                                          {/* {deposit.status === 0 && <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                <strong>{t("cancel_settlement")}</strong>{" "}
                                              </Tooltip>
                                            }
                                          >
                                            <button className="cancel-settlement-btn" onClick={() => setSettlementCancelModal(withdrawal.id)}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#FF6A6A"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6"
                                              >
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  d="M6 18L18 6M6 6l12 12"
                                                />
                                              </svg>
                                            </button>
                                          </OverlayTrigger>} */}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="payment-support-ticket-btn-sec">
                                          <div className="payment-link-dropdown">
                                            <Dropdown>
                                              <div className="payment-link-icon-sec">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    x="0"
                                                    y="0"
                                                    enableBackground="new 0 0 512 512"
                                                    version="1.1"
                                                    viewBox="0 0 512 512"
                                                    xmlSpace="preserve"
                                                  >
                                                    <circle
                                                      cx="458.667"
                                                      cy="256"
                                                      r="53.333"
                                                    ></circle>
                                                    <circle
                                                      cx="256"
                                                      cy="256"
                                                      r="53.333"
                                                    ></circle>
                                                    <circle
                                                      cx="53.333"
                                                      cy="256"
                                                      r="53.333"
                                                    ></circle>
                                                  </svg>
                                                </Dropdown.Toggle>
                                              </div>
                                              <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setModalShow(deposit)} >
                                                  <span>{t("view_btn")}</span>
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item  >
                                                  <span>{t("chat_btn")}</span>
                                                </Dropdown.Item> */}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                          {/* <div className="payment-support-btn-icon" onClick={() => {
                                            dispatch(setActiveChatSupport(deposit))
                                            setChatWidget(deposit)
                                            }}>
                                            <Button className="payment-support-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                fill="none"
                                                stroke="#212529"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                className="feather feather-message-square"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z"></path>
                                              </svg>
                                            </Button>
                                          </div> */}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <td colSpan="6" className="text-center">
                                  <NoDataFound />
                                </td>
                              )}
                            </tbody>
                          </Table>
                          <div className="new-billings-pagination-sec">
                            <Pagination
                              page={page}
                              handlePageClick={handlePageClick}
                              pageCount={Math.ceil(
                                depositList.data.payout_deposits_count
                                / 12
                              )}
                            />
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
      {modalShow && (
        <ViewPayoutModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {chatWidget && (
        <ChatWidget
          show={chatWidget}
          onHide={() => setChatWidget(false)}
        />
      )}
      {requestPayout && (
        <RequestPayoutModal
          requestPayout={requestPayout}
          closeRequestPayoutModal={closeRequestPayoutModal}
          setRequestPayout={setRequestPayout}
        />
      )}
    </>
  );
};

export default DepositPayoutIndex;
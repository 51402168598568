import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col, InputGroup } from "react-bootstrap";
import { apiKeyRegenerateStart, viewAPIKeyStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";

const ApiKeyModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("account_settings");
  const [skipRender, setSkipRender] = useState(true);
  const apiKeyView = useSelector((state) => state.admin.viewAPIKey);
  const apiKeyRegenerate = useSelector((state) => state.admin.apiKeyRegenerate);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid"))
      .required(t("password.required")),
  });

  const handleSubmit = (values) => {
    dispatch(
      props.apiKeyModal == 1 || props.apiKeyModal == 2
        ? viewAPIKeyStart(values)
        : apiKeyRegenerateStart(values)
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      (!apiKeyView.loading || !apiKeyRegenerate.loading) &&
      (Object.keys(apiKeyView.data).length > 0 || Object.keys(apiKeyRegenerate.data).length > 0)
    ) {
      setStep(2);
    }
    setSkipRender(false);
  }, [apiKeyView, apiKeyRegenerate]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.apiKeyModal}
        onHide={props.closeApiKeyModal}
      >
        <Modal.Body>
          <Button onClick={props.closeApiKeyModal} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{props.apiKeyModal == 1 || props.apiKeyModal == 2 ? t("view") : t("generate")} {props.apiKeyModal == 1 ? t("api_key") : t("salt_key")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                {step == 1 && (
                  <div className="bank-account-form-sec">
                    <Formik
                      initialValues={{
                        password: "",
                        api_key_type: props.apiKeyModal == 1 ? 1 : 2,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <FORM className="snapgopay-auth-form">
                          <div className="mb-3">
                            <Form.Label htmlFor="basic-url"> {t("password.label")}</Form.Label>
                            <InputGroup>
                              <Field
                                className="form-control"
                                placeholder={t("password.placeholder")}
                                type={showPassword ? "text" : "password"}
                                name="password"
                              />
                              <InputGroup.Text id="basic-addon3">
                                <Button className="hide-btn" onClick={()=> setShowPassword(!showPassword)}>
                                  {!showPassword ? <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> : 
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>}
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </div>
                          <div className="request-payout-payment-method-btn-sec">
                            <Button
                              className="snapgopay-cancel-btn"
                              onClick={props.closeApiKeyModal}
                            >
                              {t("cancel")}
                            </Button>
                            <Button
                              className="snapgopay-auth-btn"
                              disabled={apiKeyView.buttonDisable || apiKeyRegenerate.buttonDisable}
                              type="submit"
                            >
                              {(apiKeyRegenerate.buttonDisable || apiKeyView.buttonDisable) ? (
                                <ButtonLoader />
                              ) :
                                props.apiKeyModal == 1 || props.apiKeyModal == 2 ? t("view_btn.text") : t("view_btn.generate")
                              }
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                )}
                {step == 2 && <div className="payment-link-created-box">
                  <div className="payment-link-created-header-sec">
                    <div className="payment-link-created-header-icon-sec">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={24} height={24}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                      </svg>
                    </div>
                    <h3>{props.apiKeyModal == 1 ? t("api_key") : t("salt_key")}</h3>
                  </div>
                  <div className="payment-link-created-body-sec">
                    <div className="payment-link-created-card">
                      <div className="payment-link-created-left-sec">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={16} height={16}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                        </svg>
                        <span>
                          {props.apiKeyModal == 1 || props.apiKeyModal == 2 ? apiKeyView.data.api_key : apiKeyRegenerate.data.api_key}
                        </span>
                      </div>
                      <div className="payment-link-created-right-sec">
                        <CopyToClipboard
                          text={props.apiKeyModal == 1 || props.apiKeyModal == 2 ? apiKeyView.data.api_key : apiKeyRegenerate.data.api_key}
                          onCopy={() => getSuccessNotificationMessage(props.apiKeyModal == 1 ? t("api_key_copied_to_clipboard") : t("salt_key_copied_to_clipboard"))}>
                          <Button className="copy-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#5C9EEB"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                            </svg>
                            <span>{t("copy")}</span>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="payment-link-created-btn-sec">
                      <Button onClick={props.closeApiKeyModal} className="snapgopay-payment-btn">Done</Button>
                    </div>
                  </div>
                </div>}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApiKeyModal;

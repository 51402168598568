
import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const ChangeMobileNumberModal = (props) => {



  const formRef = useRef();
  const t = useTranslation("change_mobileNumber");
  const dispatch = useDispatch();
  const [defaultCountry, setDefaultCountry] = useState("IN");
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const [skipRender, setSkipRender] = useState(true);

  //   const validationSchema = Yup.object().shape({
  //     mobile: Yup.string()
  //       .matches(/^[0-9]{10}$/, t("user_mobile.invalid"))
  //       .required(t("user_mobile.required")),
  //   });
  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await axios.get("http://ip-api.com/json/");
        setDefaultCountry(response.data.countryCode);

      } catch (error) {
        // If the API call fails, you can set a fallback country code here.
        setDefaultCountry("IN");
      }
    };
    getUserLocation();
  }, []);


  const validateMobileNumber = (value) => {

    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("user_mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("user_mobile.required"));
      return false;
    }
  };
  const MobileNumberUpdateSchema = Yup.object().shape({

    mobile: Yup.string()
      .test("valid-mobile", t("user_mobile.invalid"), (value) => {

        if (!value) {

          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("user_mobile.required"))
      .matches(/^(?=.*[0-9])/, t("user_mobile.space_values_not_valid")),

  });


  const handleSubmit = (values) => {

    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");


      dispatch(updateProfileStart({
        name: props.profile.data.name,
        email: props.profile.data.email,
        mobile: mobile,
        country_code: countryCode,
        citizenship: props.profile.data.citizenship,
        residence: props.profile.data.residence
      }));
      props.setchangeMobileNumber(values.mobile,);

    }

  };

  useEffect(() => {
    if (!skipRender && !updateProfile.loading && Object.keys(updateProfile.data).length > 0) {
      props.closeChangeMobileNumberModal();
    }
    setSkipRender(false);
  }, [updateProfile])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.changeMobileNumber}
        onHide={props.closeChangeMobileNumberModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeChangeMobileNumberModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{t("heading")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="bank-account-form-sec">
                  <Formik
                    initialValues={{
                      mobile: `+${props.profile.data.country_code}${props.profile.data.mobile}`
                    }}
                    validationSchema={MobileNumberUpdateSchema}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                  >
                    {({ values, setFieldValue, touched, errors }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <div className="mb-3 register-phone-input">
                            <label
                              htmlFor="mobile"
                              className="form-label"
                            >
                              {t("user_mobile.label")}
                            </label>
                            <PhoneInput
                              name="mobile"
                              defaultCountry={defaultCountry}
                              placeholder={t("user_mobile.placeholder")}
                              value={values.mobile}
                              onChange={(value) =>
                                setFieldValue("mobile", value)
                              }

                              international
                              className={`${touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                                }`}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="mobile"
                              className="errorMsg"
                            />
                          </div>

                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="snapgopay-cancel-btn" onClick={() => setFieldValue("mobile", "")}>{t("reset")}</Button>
                          <Button className="snapgopay-auth-btn" disabled={updateProfile.buttonDisable || values.mobile == props.profile.data.mobile} type="submit">{updateProfile.buttonDisable ? <ButtonLoader /> : t("save_changes.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeMobileNumberModal;

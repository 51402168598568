import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Page404 = ({ buttonText }) => {
  const theme = useSelector(state => state.admin.theme);

  return (
    <div className="sec-404">
      <div className="max-w-md p-6 text-center">
        <div className="w-full">
          <img className="image-404" src={window.location.origin + "/img/404.png"} alt="something-2went-wrong" />
        </div>
        <h4 className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50 mb-3">
          Oops! Page Not Found
        </h4>
          <Button
            style={{margin:"auto"}}
            className="snapgopay-payment-btn"
            onClick={()=> window.location.href = "/"}
          >
            Back To Home
          </Button>
      </div>
    </div>
  );
}

export default Page404;
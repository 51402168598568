import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  bankAccountListStart,
  requestPayOutStart,
  settlementCancelStart,
} from "../../store/slices/WithdrawSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ButtonLoader } from "../Helper/Loader";
import { transactionStatusStart } from "../../store/slices/PaymentSlice";
import { useTranslation } from "react-multi-lang";

const SettlementCancelModal = (props) => {

  const dispatch = useDispatch();
  const t= useTranslation("cancel_settlement")
  const settlementCancel = useSelector((state) => state.withdraw.settlementCancel);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    cancel_reason: Yup.string()
      .required(t("cancel_reason.required")),
  });

  const handleSubmit = (values) => {
    dispatch(settlementCancelStart(values));
  };

  useEffect(() => {
    if (!skipRender && !settlementCancel.loading && Object.keys(settlementCancel.data).length > 0) {
      props.closeSettlementCancelModal();
    }
    setSkipRender(false);
  }, [settlementCancel]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.settlementCancelModal}
        onHide={props.closeSettlementCancelModal}
        backdrop="static"
      >
        <Modal.Body>
          <Button
            onClick={() => props.closeSettlementCancelModal()}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="request-payout-sec">
            <h3>{t("heading")}</h3>
            <Formik
              initialValues={{
                user_withdrawal_id: props.settlementCancelModal,
                cancel_reason: ""
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => (
                <FORM className="bank-account-form">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("cancel_reason.label")}</Form.Label>
                    <Field
                      className="form-control"
                      placeholder={t("cancel_reason.placeholder")}
                      name="cancel_reason"
                      type="text"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="cancel_reason"
                      className="errorMsg w-100"
                    />
                  </Form.Group>
                  <div className="request-payout-payment-method-btn-sec">
                    <Button
                      className="snapgopay-cancel-btn"
                      onClick={() => props.closeSettlementCancelModal()}
                    >
                      {t("cancel")}
                    </Button>
                    <Button type="submit" className="snapgopay-auth-btn" disabled={settlementCancel.buttonDisable}>{settlementCancel.buttonDisable ? <ButtonLoader/> : t("submit_btn.text")}</Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SettlementCancelModal;

import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { changePasswordStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import useLogout from "../../hooks/useLogout";
import { useTranslation } from "react-multi-lang";

const ChangePasswordModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("change_password");
  const changePassword = useSelector((state) => state.admin.changePassword);
  const [skipRender, setSkipRender] = useState(true);
  const {logout} = useLogout();
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")).required(t("password.required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("new_password.invalid"))
      .required(t("new_password.required"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("new_password.new_password_invalid")
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/,  t("confirm_password.invalid"))
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.same_password_invalid")
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(changePasswordStart(values))
  };

  useEffect(()=> {
    if(!skipRender && !changePassword.loading && Object.keys(changePassword.data).length > 0) {
      props.closeChangePasswordModal();
      logout();
    }

    setSkipRender(false);
  }, [changePassword])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.changePassword}
        onHide={props.closeChangePasswordModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeChangePasswordModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
          <div className="settlement-top-sec">
            <h3>{t("heading")}</h3>
          </div>
          <Row className="align-items-center">
            <Col md={12}>
                <div className="bank-account-form-sec">
                <Formik
                    initialValues={{
                      old_password: "",
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="snapgopay-auth-form">
                        <div className="mb-3">
                          <Form.Label htmlFor="basic-url"> {t("password.label")}</Form.Label>
                          <InputGroup>
                            <Field
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              type={showPassword ? "text" : "password"}
                              name="old_password"
                            />
                            <InputGroup.Text id="basic-addon3">
                              <Button className="hide-btn" onClick={()=> setShowPassword(!showPassword)}>
                                {!showPassword ? <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>}
                              </Button>
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="old_password"
                            className="errorMsg"
                          />
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("new_password.label")}</Form.Label>
                          <Field
                            name="password"
                            className="form-control"
                            type="password"
                            placeholder={t("new_password.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("confirm_password.label")}</Form.Label>
                          <Field
                            name="password_confirmation"
                            className="form-control"
                            type="password"
                            placeholder={t("confirm_password.placeholder")}

                          />
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="snapgopay-cancel-btn" onClick={()=> {
                            setFieldValue("password_confirmation", "")
                            setFieldValue("old_password", "")
                            setFieldValue("password", "")}}>
                            {t("reset")}
                          </Button>
                          <Button className="snapgopay-auth-btn" disabled={changePassword.buttonDisable} type="submit">{changePassword.buttonDisable ? <ButtonLoader/>: t("save changes_btn.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
            </Col>
          </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;

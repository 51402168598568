import React, { useEffect, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { invoicePaymentDeleteStart } from "../../store/slices/PaymentSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const DeletePaymentModal = (props) => {

  const t = useTranslation("payment.delete_payment")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoicePaymentDelete = useSelector((state) => state.payment.invoicePaymentDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !invoicePaymentDelete.loading && Object.keys(invoicePaymentDelete.data).length > 0) {
      props.closeDeletePaymentModal();
      props.navigate && navigate("/invoice-payment");
    }
    setSkipRender(false);
  }, [invoicePaymentDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.deletePaymentModal}
        onHide={props.closeDeletePaymentModal}
        backdrop="static"
      >
        <Modal.Body>
          <Button onClick={()=> props.closeDeletePaymentModal()} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="payment-link-created-box">
            <div className="payment-link-created-header-sec">
              <div className="payment-link-created-header-icon-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="none"
                  viewBox="0 0 31 32"
                >
                  <g
                    fill="#111"
                    stroke="#111"
                    strokeWidth="0.5"
                    clipPath="url(#clip0_21_5094)"
                  >
                    <path d="M13.668 21.488l-4.702-.005c-2.83-.042-5.11-2.45-5.092-5.376.017-2.87 2.242-5.185 5.017-5.222l4.704.003c.563 0 1.016-.472 1.012-1.054-.005-.582-.464-1.054-1.027-1.055l-4.704-.002c-3.956-.003-7.14 3.311-7.11 7.403.03 4.09 3.26 7.41 7.217 7.413l4.702.005c.563-.01 1.008-.489.994-1.07-.014-.569-.46-1.027-1.009-1.037l-.002-.003h0zM30.428 16.174a7.544 7.544 0 00-2.132-5.242c-1.35-1.394-3.178-2.177-5.078-2.175L18.51 8.75c-.563 0-1.016.47-1.012 1.053.004.582.463 1.055 1.026 1.056l4.704.007a5.047 5.047 0 013.634 1.555c2.015 2.072 2.038 5.428.05 7.495a5 5 0 01-3.611 1.547l-4.703-.005c-.563 0-1.016.47-1.012 1.053.004.583.464 1.055 1.027 1.056l4.7.007c3.955 0 7.137-3.31 7.114-7.399z"></path>
                    <path d="M20.415 15.123l-8.649-.009c-.563-.01-1.024.453-1.03 1.035-.005.582.446 1.062 1.01 1.073h.035l8.649.01c.562-.01 1.007-.49.993-1.072-.013-.568-.459-1.026-1.008-1.037z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_21_5094">
                      <path
                        fill="#fff"
                        d="M0 0H31V31H0z"
                        transform="translate(0 .5)"
                      ></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h3>{t("invoice_title")}</h3>
              <p>{t("invoice_description")}</p>
            </div>
            <div className="payment-link-created-body-sec">
              <div className="payment-link-created-btn-sec">
                <Button
                  onClick={() =>
                    dispatch(
                      invoicePaymentDeleteStart({
                        invoice_link_id : props.deletePaymentModal,
                      })
                    )
                  }
                  className="snapgopay-payment-btn"
                  disabled={invoicePaymentDelete.buttonDisable}
                >
                  {invoicePaymentDelete.buttonDisable ? <ButtonLoader/> : t("delete") }
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePaymentModal;

import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Image, Modal } from "react-bootstrap";
import "../PaymentLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { transactionUpdateStart } from "../../../store/slices/PaymentSlice";
import Select from "react-select";
import axios from "axios";
import { ButtonLoader } from "../../Helper/Loader";
import { useNavigate } from "react-router-dom";
import PaymentCancel from "../PaymentCancel";
import { useTranslation } from "react-multi-lang";

const PayOutTwo = (props) => {
const t = useTranslation("payout_two")

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [token, setToken] = useState({
    type: "ETH",
    value: 0,
  });

  const transactionView = useSelector((state) => state.payment.transactionView);
  const transactionUpdate = useSelector((state) => state.payment.transactionUpdate);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cardSchema = Yup.object().shape({
    bank_name: Yup.string().required(t("bank.required")),
    account_holder_name: Yup.string().required(t("account_holder.required")),
    account_number: Yup.string().required(t("account_number.required")),
    ifsc_code: Yup.string().required(t("ifsc_code.required")),
  });

  const cryptoSchema = Yup.object().shape({
    user_wallet_address: Yup.string().required(t("wallet_address.required")),
  });

  const upiSchema = Yup.object().shape({
    user_upi_id: Yup.string().required(t("upi_id.required")),
  });

  const handleSubmit = (values) => {
    let data = {};
    if (transactionView.data.transaction.payment_type == "crypto")
      data = { ...data, crypto_type: token.type };
    dispatch(
      transactionUpdateStart({
        step: 3,
        transaction_unique_id: transactionView.data.transaction.unique_id,
        user_email: props.email,
        amount: transactionView.data.transaction.amount,
        ...data,
        ...values,
      })
    );
  };

  const options = [
    { value: "ethereum", label: "ETH" },
    { value: "binance-usd-linea", label: "BUSD" },
    { value: "usd-coin", label: "USDC" },
    { value: "tether", label: "USDT" },
  ];

  useEffect(() => {
    if (transactionView.data.transaction.payment_type == "crypto") {
      getTokenValue("ethereum", "ETH");
    }
  }, []);

  const getTokenValue = async (from, type) => {
    if (!localStorage.getItem(from)) {
      const token_value = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${from}&vs_currencies=usd`
      );
      if (token_value.data[from] && token_value.data[from]["usd"]) {
        localStorage.setItem(from, token_value.data[from]["usd"]);
        setToken({
          type: type,
          value:
            transactionView.data.transaction.amount /
            token_value.data[from]["usd"],
        });
      }
    } else {
      setToken({
        type: type,
        value:
          transactionView.data.transaction.amount / localStorage.getItem(from),
      });
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !transactionUpdate.loading &&
      Object.keys(transactionUpdate.data).length > 0
    ) {
      props.setStep(3);
    }
    setSkipRender(false);
  }, [transactionUpdate]);

  return (
    <>
      <div className="snapgopay-payment-form-wrap">
        <div className="snapgopay-form-wrap-top">
          <div className="form-heading">
            <h2>{t("heading")}</h2>
          </div>
          <div className="snapgopay-total-amount-frame">
            <p>{t("amount")}</p>
            <h3>{props.transaction.formatted_amount}</h3>
          </div>
        </div>
        {transactionView.data.transaction.payment_type == "crypto" && (
          <div className="snapgopay-form-wrap-middle">
            <div className="snapgopay-pay-method-info">
              <p> {t("token_type")}</p>
              <div className="total-amount-crypt">
                <Select
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  onChange={(token) => getTokenValue(token.value, token.label)}
                  placeholder={
                    <div className="placeholder-flex">{token.type}</div>
                  }
                />
                <h4>
                  {transactionView.data.transaction.formatted_amount}{" "}
                  <span> = {token.value}</span>
                </h4>
              </div>
            </div>
          </div>
        )}
        <div className="snapgopay-form-wrap-bottom">
          <Formik
            initialValues={
              props.transaction.payment_type == "card"
                ? {
                    bank_name: "",
                    account_holder_name: "",
                    account_number: "",
                    ifsc_code: "",
                  }
                : props.transaction.payment_type == "upi"
                ? {
                    user_upi_id: "",
                  }
                : {
                    user_wallet_address: "",
                  }
            }
            validationSchema={
              props.transaction.payment_type == "card"
                ? cardSchema
                : props.transaction.payment_type == "upi"
                ? upiSchema
                : cryptoSchema
            }
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM>
                {props.transaction.payment_type == "card" ? (
                  <div className="card-details-form">
                     <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("bank.name")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("bank.placeholder")}
                        name="bank_name"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="bank_name"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("account_holder.name")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("account_holder.placeholder")}
                        name="account_holder_name"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_holder_name"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("account_number.name")}:</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("account_number.placeholder")}
                        name="account_number"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_number"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("ifsc_code.name")}:</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("ifsc_code.placeholder")}
                        name="ifsc_code"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="ifsc_code"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  </div>
                ) : props.transaction.payment_type == "upi" ? (
                  <div className="card-details-form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("upi_id.name")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("upi_id.placeholder")}
                        name="user_upi_id"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="user_upi_id"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  </div>
                ) : (
                  <div className="card-details-form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("wallet_addrrss.name")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("wallet_addrrss.placeholder")}
                        name="user_wallet_address"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="user_wallet_address"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  </div>
                )}
                <div className="form-wrap-btn">
                  <Button type="button" className="btn-cancel" onClick={handleShow}>
                   {t("cancel")}
                  </Button>
                  <Button className="btn-next" type="submit" disabled={transactionUpdate.buttonDisable}>
                    {transactionUpdate.buttonDisable ? <ButtonLoader/> : t("submit")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          {show && <PaymentCancel show={show} handleClose={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default PayOutTwo;
